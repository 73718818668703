import React from 'react';
import { TChangedProperties, TChangedProperty } from '../../../../actions/changedProperties.types';
import { TNavigatorPropertiesData } from '../../../../models/navigatorPropertiesSelectorState.types';
import { FormGroup } from '../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { Checkbox, Form } from 'antd';
import { useIntl } from 'react-intl';
import {
    PROP_SCRIPT_ENGINE_EDIT_DISABLED,
    PROP_USER_EDIT_DISABLED,
} from '../../../../models/properties/accessible-properties';
import messages from '../../messages/Settings.messages';
import { TEnumPropertyValue } from '../../../../models/properties/TEnumPropertyDescriptor.types';
import { TPropertyValue } from '../../../../models/properties/property-descriptor.types';
import { useSelector } from 'react-redux';
import { isUserHasAccess } from '../../../../selectors/authorization.selectors';
import { Icon } from '@/modules/UIKit/components/Icon/Icon.component';
import iconCopy from '../../../../resources/icons/copy.svg';
import { dateFormat, timeNoSecondsFormat, timestampToMomentDate } from '@/utils/date.time.utils';
import { NodeId } from '@/serverapi/api';
import theme from './PropertySettings.scss';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { PublicLinkBLLService } from '@/services/bll/PublicLinkBLLService';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { TServerEntity } from '@/models/entities.types';

type TPropertySettingsProps = {
    changedProperties: TChangedProperties;
    propertiesData: TNavigatorPropertiesData;
    onChangeProperties: (changedProperties: TChangedProperties) => void;
    changeCheckedStatus?: React.Dispatch<React.SetStateAction<boolean>>;
    isChecked?: boolean;
    publishedBy?: string;
    publishedAt?: number;
    nodeId?: NodeId;
    isModel?: boolean;
    onCopyModelLink?: (nodeId: NodeId) => void;
};

export const PropertySettings = (props: TPropertySettingsProps) => {
    const {
        changedProperties,
        propertiesData,
        onChangeProperties,
        changeCheckedStatus,
        isChecked,
        publishedBy,
        publishedAt,
        nodeId,
        isModel,
        onCopyModelLink,
    } = props;
    const intl = useIntl();

    const server: TServerEntity | undefined = useSelector(
        nodeId?.serverId ? ServerSelectors.server(nodeId.serverId) : () => undefined,
    );
    const modelLinkPath: string = server
        ? PublicLinkBLLService.createPublicLink(server.url, nodeId?.repositoryId!, nodeId?.id!)
        : '';

    const hasAccessToChange: boolean = useSelector(isUserHasAccess('LOCK_ADMIN'));
    const isPublisher: boolean = useSelector(isUserHasAccess('PUBLISHER'));
    const publishedDate: string | undefined = timestampToMomentDate(publishedAt || -1)?.format(
        `${dateFormat} ${intl.formatMessage(messages.at)} ${timeNoSecondsFormat}`,
    );
    const scriptEngineEditDisabledChangedProperty: TChangedProperty =
        changedProperties[PROP_SCRIPT_ENGINE_EDIT_DISABLED.key];
    const userEditDisabledChangedProperty: TChangedProperty = changedProperties[PROP_USER_EDIT_DISABLED.key];
    const scriptEngineEditDisabledProperty: TPropertyValue | TEnumPropertyValue =
        propertiesData[PROP_SCRIPT_ENGINE_EDIT_DISABLED.key];
    const userEditDisabledProperty: TPropertyValue | TEnumPropertyValue = propertiesData[PROP_USER_EDIT_DISABLED.key];

    const updateProperties = (key: string, value: TChangedProperty) => {
        onChangeProperties({
            ...props.changedProperties,
            [key]: value,
        });
    };

    const onChangePublisherCheckedHandler = (value: CheckboxChangeEvent): void => {
        changeCheckedStatus && changeCheckedStatus(value.target.checked);
    };

    const onCopyModelLinkHandler = (nodeId: NodeId | undefined): void => {
        if (nodeId) {
            onCopyModelLink && onCopyModelLink(nodeId);
        }
    };

    return (
        <div>
            <FormGroup>
                <Form.Item>
                    <Checkbox
                        checked={
                            (userEditDisabledChangedProperty
                                ? userEditDisabledChangedProperty.value?.value
                                : userEditDisabledProperty.value?.value) === 'true'
                        }
                        disabled={!hasAccessToChange}
                        onChange={(value) => {
                            updateProperties(PROP_USER_EDIT_DISABLED.key, {
                                ...userEditDisabledProperty,
                                value: {
                                    ...userEditDisabledProperty.value,
                                    value: String(value.target.checked),
                                },
                            } as TChangedProperty);
                        }}
                    >
                        {intl.formatMessage(messages.userEditDisabled)}
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Checkbox
                        checked={
                            (scriptEngineEditDisabledChangedProperty
                                ? scriptEngineEditDisabledChangedProperty.value?.value
                                : scriptEngineEditDisabledProperty.value?.value) === 'true'
                        }
                        disabled={!hasAccessToChange}
                        onChange={(value) => {
                            updateProperties(PROP_SCRIPT_ENGINE_EDIT_DISABLED.key, {
                                ...scriptEngineEditDisabledProperty,
                                value: {
                                    ...scriptEngineEditDisabledProperty.value,
                                    value: String(value.target.checked),
                                },
                            } as TChangedProperty);
                        }}
                    >
                        {intl.formatMessage(messages.scriptEngineEditDisabled)}
                    </Checkbox>
                </Form.Item>
            </FormGroup>
            <FormGroup>
                {isModel && (
                    <Form.Item>
                        <Checkbox
                            className={theme.checkBoxItem}
                            checked={isChecked}
                            disabled={!isPublisher}
                            onChange={onChangePublisherCheckedHandler}
                        >
                            <span className={theme.checkboxTextLabel}>
                                {intl.formatMessage(messages.allowUnauthorizedAccess)}
                            </span>
                        </Checkbox>
                        {publishedBy && (
                            <Form.Item>
                                <div className={theme.modelLinkWrapper}>
                                    <div className={theme.modelLink}>{modelLinkPath}</div>
                                    <div className={theme.copyLinkIcon}>
                                        <Icon onClick={() => onCopyModelLinkHandler(nodeId)} spriteSymbol={iconCopy} />
                                    </div>
                                </div>
                                <div className={theme.publishedInfo}>
                                    {intl.formatMessage(messages.publishedInfo)}
                                    <span>{`${publishedBy} ${publishedDate}`}</span>
                                </div>
                            </Form.Item>
                        )}
                    </Form.Item>
                )}
            </FormGroup>
        </div>
    );
};
